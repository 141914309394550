import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';

import { getApplicationRoutes } from './routes';

import type { IAppData } from './types';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
) => {
  const routes = await getApplicationRoutes(wixCodeApi.site);

  return {
    routes,
  } as IAppData;
};
